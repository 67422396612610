/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Menu, Icon, Confirm, Popup, Modal, Button, Dropdown, Label,
} from 'semantic-ui-react';
import { BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import logo from '../../logo.png';
import Settings from '../../Settings';
import moment from 'moment'
import Utility from '../../Utility';
import DropdownBranch from '../Widget/DropDown/DropDownBranch'
import { activations_branch, loadBills, loadLedger, loadDailyLedger } from '../../actions'
import AllPagesPDFViewer from '../pdf/all-pages';

var config = require('../../config')
class StaffInfo extends Component {
    handleBranchChange(e, { value }) {
        const { branches } = this.props
        const branch = branches.find((item) => item.id == value)
        this.props.handleBranchChange(branch)
    }

    render() {
        const { auth } = this.props
        const { user, branch, role } = auth
        let infoText = <b>{user.username}</b>;
        let b = branch ? <small><i>{branch.name}</i> </small> : ''
        let width = (200 * 0.9) + 'px'
        var ellipsisStyle = {
            whiteSpace: 'nowrap',
            width: width,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
        }
        const Width300 = {
            // 'width':'1000px',
            'word-break': 'break-word',
        }
        return (
            <div style={{ padding: '0px', textAlign: 'left', paddingRight: '10px' }}>
                <Popup
                    position={"left center"}
                    header=""
                    content={infoText}
                    style={Width300}
                    trigger={
                        <div style={ellipsisStyle} ><Icon name='user circle' color='grey' />{infoText}</div>}
                />
                {/* <div style={ellipsisStyle}>
                {infoText}
            </div> */}
                {/* <div className="pull-right">{role==='A' || role==='M' || role==='S' ? <DropdownBranch  fluid size='small' selection onChange={this.handleBranchChange.bind(this)} name="branch" value={branch.id}  />:''}</div> */}
            </div>
        );
    }
}

class NavBar extends Component {

    constructor(props) {
        super(props);
        const path = window.location.pathname.split('/')
        this.state = {
            active_path: path[0] + '/' + path[1],
            manuals: [],
        }
        this.handleItemClick = this.handleItemClick.bind(this)
    }

    handleBranchChange(e, { value }) {
        const { branches } = this.props
        const branch = branches.find((item) => item.id == value)
        this.props.handleBranchChange(branch)
    }

    handleItemClick(e, { name, to }) {
        const path = to.split('/')
        this.setState({ active_path: path[0] + '/' + path[1] });
    }

    handleItemClickMenu() {
        window.location.href = "/stock-ready";
    }

    handleItemClickExpense() {
        const _token = localStorage.getItem('token')
        if (_token) {
            const token = JSON.parse(localStorage.getItem('token'))
            window.location.href = `${Settings.expenseUrl}?token=${token.access_token}`;
        }
    }

    openManual(e) {
        const r = Utility.getAsync(Settings.baseUrl + '/handbook/')
        Promise.all([r]).then((v) => {
            console.log(v[0].data)
            this.setState({
                manual_open: true,
                manuals: v[0].data,
            })
        });
    }

    onHandleLogout() {
        const url = Settings.revokeTokenUrl;
        const token = JSON.parse(localStorage.getItem('token'))
        const formData = {
            token: token.access_token,
            grant_type: 'password',
            client_id: Settings.client_id,
            client_secret: Settings.client_secret
        };
        this.setState({
            confirm_open: false
        })
        Utility.postBasic(url, formData, (status, data, code) => {
            if (code == 200) {
                localStorage.setItem("token", JSON.stringify({}));
                localStorage.removeItem('branch')
                this.props.onLogout()
                localStorage.removeItem('noti')
            }
        })
    }

    render() {
        const { auth, noti_system, branches } = this.props
        const { role } = auth
        const active_path = role == 'C' ? '/report' : this.state.active_path
        const is_redeem = localStorage.getItem('is_redeem')

        var optionBranchRedeem = []
        if (is_redeem) {
            const branchRedeem = branches.find((item) => item.id == 1)
            if (branchRedeem) {
                optionBranchRedeem = [{
                    key: 0,
                    text: branchRedeem.code + ' - ' + branchRedeem.name,
                    value: branchRedeem.id,
                }]
            }
        }
        return (
            <>
                {is_redeem ?
                    <Menu pointing secondary size='small' className='top-menubar' >
                        {role == 'U' || role == 'A' || role == 'M' || role == 'S' ?
                            <Menu.Item
                                color='black'
                                name='home'
                            >
                                <b>AAG</b>
                            </Menu.Item>
                            : ''}
                        <Menu.Menu position='right'>
                            <Menu.Header>
                                <div className="pull-right">
                                    <Dropdown className='dropdown-icon' fluid size='small' selection name="branch" options={optionBranchRedeem} value={1} />
                                </div>
                            </Menu.Header>
                            <Menu.Item>
                                <Dropdown icon='chevron circle down' simple>
                                    <Dropdown.Menu>
                                        <Dropdown.Item style={{ width: '200px' }}>
                                            <StaffInfo auth={auth} {...this.props} />
                                        </Dropdown.Item>
                                        <Dropdown.Item id='logout'
                                            onClick={() => this.setState({ confirm_open: true })}
                                            style={{ width: '200px' }}>
                                            <b><Icon name='power' color='red' /></b> ออกจากระบบ
                                            <Confirm id='confirmLogout'
                                                content='คุณต้องการออกจากระบบ'
                                                open={this.state.confirm_open}
                                                onCancel={() => {
                                                    this.setState({ confirm_open: false });
                                                }}
                                                onConfirm={this.onHandleLogout.bind(this)} />
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                    :
                    <Menu pointing secondary size='small' className='top-menubar' >
                        {role == 'U' || role == 'A' || role == 'M' || role == 'S' ?
                            <Menu.Item
                                color='black'
                                name='home'
                                active={active_path == '/'}
                                onClick={this.handleItemClick}
                                as={Link}
                                to='/'><Icon name='home' color='black' />
                                <b>AAG</b>
                            </Menu.Item>
                            : ''}
                        {role == 'A' || role == 'M' || role == 'S'
                            ? <Menu.Item id='btnSetting'
                                color='purple'
                                name='files'
                                active={active_path == '/files'}
                                onClick={this.handleItemClick}
                                as={Link}
                                to={role == 'M' ? '/files/staffs' : '/files/branches'}>
                                <Icon name='folder outline' color='purple' />
                                แฟ้มข้อมูล
                            </Menu.Item>
                            : ''}
                        {role == 'A' || role == 'M' || role == 'S'
                            ? <Menu.Item id='btnStock'
                                color='yellow'
                                name='stock'
                                active={active_path == '/stock'}
                                onClick={this.handleItemClick}
                                as={Link}
                                to='/stock/import'>
                                <Icon name='shipping' color='yellow' />
                                สต็อกทอง
                            </Menu.Item>
                            : ''}
                        {role == 'U' || role == 'A' || role == 'M' || role == 'S' ?
                            <Menu.Item id='btnCustomer'
                                name='customer'
                                color='olive'
                                active={active_path == '/customer'}
                                onClick={this.handleItemClick}
                                as={Link}
                                to='/customer'>
                                <Icon name='users' color='olive' />
                                ลูกค้า
                            </Menu.Item>
                            : ''}
                        {role == 'U' || role == 'A' || role == 'M' || role == 'S' ?
                            <Menu.Item id='btnPOS'
                                name='sell'
                                color='red'
                                active={active_path == '/pos'}
                                onClick={this.handleItemClick}
                                as={Link}
                                to='/pos'>
                                <Icon name='cart' color='red' />
                                POS ซื้อ-ขาย
                            </Menu.Item>
                            : ''}
                        {role == 'A' || role == 'M' || role == 'S' ?
                            <Menu.Item id='btnPOSStaff'
                                name='sell'
                                color='orange'
                                active={active_path == '/pos_staff'}
                                onClick={this.handleItemClick}
                                as={Link}
                                to='/pos_staff'>
                                <Icon name='cart' color='orange' />
                                POS ซื้อ-ขาย (พนักงาน)
                            </Menu.Item>
                            : ''}
                        {role == 'U' || role == 'A' || role == 'M' || role == 'S' ?
                            <Menu.Item id='btnLease'
                                name='lease'
                                color='teal'
                                active={active_path == '/lease'}
                                onClick={this.handleItemClick}
                                as={Link}
                                to='/lease/pos'><Icon name='calendar alternate' color='teal' />
                                ขายฝาก
                            </Menu.Item>
                            : ''}
                        {role == 'U' || role == 'A' || role == 'M' || role == 'S' ?
                            <Menu.Item id='btnSaving'
                                name='saving'
                                color='grey'
                                active={active_path == '/saving'}
                                onClick={this.handleItemClick}
                                as={Link}
                                to='/saving/pos'><Icon name='money' color='grey' />
                                ออมทอง
                            </Menu.Item>
                            : ''}
                        {role == 'U' || role == 'A' || role == 'M' || role == 'S' ?
                            <Menu.Item id='btnReportMoney'
                                color='blue'
                                name='report1'
                                active={active_path == '/ledger'}
                                onClick={this.handleItemClick}
                                as={Link}
                                to='/ledger'>
                                <Icon name='book' color='blue' />รายรับ-รายจ่าย
                            </Menu.Item>
                            : ''}
                        {role == 'U' || role == 'A' || role == 'M' || role == 'S' || role == 'C' ?
                            <Menu.Item id='btnReport'
                                color='brown'
                                name='report1'
                                active={active_path == '/report'}
                                onClick={this.handleItemClick}
                                as={Link}
                                to='/report'>
                                <Icon name='bar chart' color='brown' />
                                รายงาน
                            </Menu.Item>
                            : ''}
                        {
                            role == 'U' || role == 'A' || role == 'M' || role == 'S' || role == 'C' ?
                                <Menu.Item>
                                    <Dropdown
                                        icon='plus square outline'
                                        simple
                                        direction='left'
                                        text='เพิ่มเติม'
                                    >
                                        <Dropdown.Menu>
                                            {role == 'A' || role == 'S' || role == 'M' || role == 'U' ?
                                                <Dropdown.Item id='btnReward'
                                                    color='pink'
                                                    name='report1'
                                                    active={active_path == '/reward'}
                                                    onClick={this.handleItemClick}
                                                    as={Link}
                                                    to={role == 'M' || role == 'U' ? '/reward/pickup' : '/reward/category'}>
                                                    <Icon name='gift' color='pink' />
                                                    ของรางวัล
                                                </Dropdown.Item>
                                                : ''}
                                            {role == 'U' || role == 'A' || role == 'M' || role == 'S' || role == 'C' ?
                                                <Dropdown.Item id='btnStockReady'
                                                    color='purple'
                                                    name='stockready'
                                                    onClick={this.handleItemClickMenu}
                                                >
                                                    <Icon name='gift' color='purple' />
                                                    AA Gold (รับที่สาขา)
                                                </Dropdown.Item>
                                                : ''}
                                            {role == 'U' || role == 'A' || role == 'M' || role == 'S' || role == 'C' ?
                                                <Dropdown.Item id='btnExpense'
                                                    color='purple'
                                                    name='expense'
                                                    onClick={this.handleItemClickExpense}
                                                >
                                                    <Icon name='shopping bag' color='red' />
                                                    ค่าใช้จ่าย
                                                </Dropdown.Item>
                                                : ''}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Menu.Item> : ''
                        }
                        <Menu.Menu position='right'>
                            {role == 'A' || role == 'M' || role == 'S' ?
                                <Menu.Item
                                    name='bell outline'
                                    id='alertIcon'
                                >
                                    <Dropdown
                                        icon='bell outline'
                                        disabled={noti_system.list.length == 0}
                                        simple
                                        direction='left'
                                    >
                                        <Dropdown.Menu>
                                            {noti_system.list.map((row, i) =>
                                                <Dropdown.Item
                                                    key={i}
                                                    onClick={(e) => {
                                                        localStorage.setItem('link', true)
                                                        window.location.assign(window.location.origin + noti_system.link[row.kind])
                                                    }}
                                                    text={row.detail}
                                                    icon={noti_system.icon[row.kind]}
                                                />
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {noti_system.list.length != 0 &&
                                        <Label color='red' floating size='mini'>{noti_system.list.length}</Label>
                                    }
                                </Menu.Item>
                                : ''}
                            <Menu.Header>
                                <div className="pull-right">
                                    {(auth.role === 'A' || auth.role === 'M' || auth.role === 'S' || auth.role === 'U') ?
                                        <DropdownBranch showCode={true} className='dropdown-icon' fluid size='small' selection onChange={this.handleBranchChange.bind(this)} name="branch"
                                            value={auth.branch == undefined ? '' : auth.branch.id}
                                        /> : ''}</div>
                            </Menu.Header>
                            <Menu.Item>
                                <Dropdown icon='chevron circle down' simple>
                                    <Dropdown.Menu>
                                        <Dropdown.Item style={{ width: '200px' }}>
                                            <StaffInfo auth={auth} {...this.props} />
                                        </Dropdown.Item>
                                        <Dropdown.Item id='manual'
                                            style={{ width: '200px' }}
                                            onClick={this.openManual.bind(this)}>
                                            <b><Icon name='help circle' color='grey' /></b> ช่วยเหลือ
                                            <Modal open={this.state.manual_open} size='mini' /*dimmer='blurring'*/>
                                                <Button
                                                    id='close_modal'
                                                    circular
                                                    icon='close'
                                                    basic
                                                    floated='right'
                                                    name=''
                                                    onClick={() => this.setState({ manual_open: false })} />
                                                <Modal.Header>{'ช่วยเหลือ'}</Modal.Header>
                                                <Modal.Content className='scrolling'>
                                                    <Modal.Description>
                                                        <h4>คู่มือ</h4>
                                                        <Menu vertical fluid id='menumanual'>
                                                            {this.state.manuals.map((row, i) => {
                                                                return (
                                                                    <Menu.Item
                                                                        href={row.manual}
                                                                        target='_blank'
                                                                        onClick={() => this.setState({
                                                                            manual_open: false,
                                                                            // iframe: true,
                                                                            // src: row.manual,
                                                                        })}
                                                                    >
                                                                        - {row.title}
                                                                    </Menu.Item>
                                                                )
                                                            })}
                                                        </Menu>
                                                    </Modal.Description>
                                                </Modal.Content>
                                            </Modal>
                                            <Modal open={this.state.iframe} size='fullscreen' /*dimmer='blurring'*/>
                                                <Button
                                                    id='close_modal'
                                                    circular
                                                    icon='close'
                                                    basic
                                                    floated='right'
                                                    name=''
                                                    onClick={() => this.setState({ iframe: false })} />
                                                <Modal.Header>{'แฟ้มข้อมูล'}</Modal.Header>
                                                <Modal.Content className='scrolling'>
                                                    <Modal.Description>
                                                        <AllPagesPDFViewer pdf={this.state.src} />
                                                    </Modal.Description>
                                                </Modal.Content>
                                            </Modal>
                                        </Dropdown.Item>
                                        <Dropdown.Item id='logout'
                                            onClick={() => this.setState({ confirm_open: true })}
                                            style={{ width: '200px' }}>
                                            <b><Icon name='power' color='red' /></b> ออกจากระบบ
                                            <Confirm id='confirmLogout'
                                                content='คุณต้องการออกจากระบบ'
                                                open={this.state.confirm_open}
                                                onCancel={() => {
                                                    this.setState({ confirm_open: false });
                                                }}
                                                onConfirm={this.onHandleLogout.bind(this)} />
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                }
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    handleBranchChange: (branch) => {
        activations_branch(dispatch, branch)
        let querytoday = Utility.jsonToQueryString({
            start_date: Utility.formatDate2(moment()),
            end_date: Utility.formatDate2(moment()),
            branch: branch.id,
            is_enabled: 1,
            page: 1
        });
        if (window.location.pathname == '/pos_staff') {
            querytoday += '&for_staff=true'
        }
        loadBills(dispatch, querytoday)
        loadLedger(dispatch, querytoday, 1)
        loadDailyLedger(dispatch, moment(), branch.id)
    },
    onLoadBillList: filter => loadBills(dispatch, filter)

})

const mapStateToProps = ({ auth, branches, noti_system, }) => {
    return ({ auth, branches, noti_system, })
}
export default connect(
    mapStateToProps, mapDispatchToProps
)(NavBar)