/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from 'react';
import Dashboard from '../Dashboard';
import ModalGoldPrice from '../GoldPrice/ModalGoldPrice';
import Files from '../Files';
import Stock from '../Stock';
import Lease from '../Lease';
import Report from '../Report';
import Pos from '../POS';
import PosStaff from '../POSStaff';
import Reward from '../Reward'
import Ledger from '../Ledger'
import Customer from '../Customer'
import NavBar from '../NavBar'
import Savings from '../Savings'

import {BrowserRouter as Router, Route, Link,Redirect} from 'react-router-dom'
import {Dimmer, Loader, Label} from 'semantic-ui-react'
import Utility from '../../Utility';
import Settings from '../../Settings';
import {loadBranch,loadLedgerCategory} from '../../actions'
import { connect } from 'react-redux'
var config = require( '../../config' )

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active_path: '/',
            modal_gold: false
        }

        this.handleItemClick = this.handleItemClick.bind(this);
        this.fullscreen = this.fullscreen.bind(this)
    }

    handleItemClick(e, {to}) {

        this.setState({active_path: to});
    }

    fullscreen() {
        function launchIntoFullscreen(element) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            }
        }

        function hideFullScreen() {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }

        if (this.state.is_full) {
            hideFullScreen()
            this.setState({is_full: false})
        } else {
            launchIntoFullscreen(document.documentElement);
            this.setState({is_full: true})
        }
    }

    async componentDidMount() {
        const {dispatch} = this.props

        await loadLedgerCategory(dispatch)
        await loadBranch(dispatch)
        this.setState({
            active_path : window.location.pathname,
            is_ready: true
        })

    }

    render() {

        const {auth} = this.props
        const {role} = auth
        const {is_ready} = this.state
        return (
            <div>
                {!is_ready &&
                <Dimmer active inverted>
                <Loader inverted>Loading..</Loader>
              </Dimmer>
            }
                {is_ready && <Router>
                    <div className='menu-top'>
                        <NavBar onLogout={this.props.onLogout} />
                        
                        <div className='segment' id='root-content' heigth={500}>
                        {config.env != 'production' && <div id="label-version">
                            <span>POS Version - {config.env}({Settings.version})</span>
                        </div>}
                        {role == 'U' || role == 'A' || role == 'M' || role == 'S'?
                            <Route
                                exact
                                path="/"
                                component={() => {
                                return (<Dashboard  onEditGold={() => { this.setState({open_gold: true})  }}/>)
                            }}/> 
                            : 
                            <Route
                                exact
                                path="/"
                                component={Report}/> 
                            }
                            {role == 'A' || role == 'M' || role == 'S' ? <Route path="/files" component={Files}/> : ''}
                            {role == 'A' || role == 'M' || role == 'S' ? <Route path="/stock" component={Stock}/> : ''}
                            {role == 'A' || role == 'M' || role == 'S' || role == 'U' ? <Route path="/customer" component={Customer}/> : ''}

                            {role == 'A' || role == 'M' || role == 'S' || role == 'U' ? <Route path="/pos" component={Pos}/>: ''}
                            {role == 'A' || role == 'M' || role == 'S' ? <Route path="/pos_staff" component={PosStaff}/>: ''}
                            {role == 'A' || role == 'M' || role == 'S' || role == 'U' || role =='C' ?<Route path="/report" component={Report}/>:''}
                            {role == 'A' || role == 'M' || role == 'S' || role == 'U' ? <Route path="/lease" component={Lease}/> :''}
                            {role == 'A' || role == 'M' || role == 'S' || role == 'U' ? <Route path="/saving" component={Savings}/> :''}
                            {role == 'A' || role == 'M' || role == 'S' || role == 'U' ? <Route path="/ledger" component={Ledger}/>:''}
                            {role == 'A' || role == 'M' || role == 'S' || role == 'U' ? <Route path="/reward" component={Reward}/>:''}
                        </div>

                    </div>
                </Router>}
                {this.state.open_gold
                    ? <ModalGoldPrice
                            onClose={() => {
                            this.setState({open_gold: false})
                        }}/>
                    : ''}
            </div>
        );
    }
}

const mapStateToProps = ({auth}) =>{
    return ({auth})
  }
  export default connect(
    mapStateToProps
  )(Main)