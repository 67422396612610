const api = window.location.href.includes('pos-public.aagold-th')?"https://mgmt-sys-public.aagold-th.com":"https://mgmt-sys.aagold-th.com"
const api_report = window.location.href.includes('pos-public.aagold-th')?"https://report-api.aagold-th.com":"https://report-api.aagold-th.com"
const baseMobileUrl = 'https://m.aagold-th.com/'
module.exports = {
    debug: true,
    env: "production",
    api: {
      url: api,
      url_report: api_report,
    },
    client: {
      id: "VMayVhr7H3IJpTOEyPdKB4VbiD62xLp6uzSf5ZZa",
      secret: "VU5LCjEEBxmj6CqAQN3c4Ju4L6CoVBiWe4lQMTwRKnhiYChg85FgGO9ujNYFtWukMsf6Twmco6syO1xusWvXvvNbeE1HtfQBEaauz2JUdWmEG6JrPFP3mRzYExo1NbLf"
    },
    baseMobileUrl: baseMobileUrl,
    link: {
      expense: "https://expenses.aagold-th.com/"
    },
    url_genpdf: 'https://pos.aagold-th.com/genpdf'
  };
